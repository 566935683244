import '../styles/index.scss';
import React from 'react';
import { Link } from 'gatsby';
import SEO from '../components/seo';

const NotFoundPage = () => (
	<React.Fragment>
		<SEO title="404" description="This is probably not the page you were looking for." />
		<div className="notfound-container">
			<div className="notfound-inner-container">
				<div className="notfound-title">404</div>
				<div className="notfound-message">
					Our apologies, this is probably not the page you were looking for. Let's take you{' '}
					<Link to="/">
						<span style={{ color: '#22B2F2', cursor: 'pointer' }}>home</span>
					</Link>.
				</div>
				<svg
					className="notfound-catbear"
					id="Layer_1"
					data-name="Layer 1"
					xmlns="http://www.w3.org/2000/svg"
					viewBox="0 0 279.21 88.1"
				>
					<path
						d="M117.39 23.48s-1.06-3.26-.47-5.24a6.07 6.07 0 015.92-3.51s3.42.32 4.22 3c0 0 10.31-1.77 16.44 4.79 0 0 3.18-3.05 5.58.37 0 0 3.17 5.4-2.92 7.1 0 0-.4 15-18.3 11-.02.02-16.84-2.31-10.47-17.51z"
						fill="#fff"
						stroke="#344357"
						strokeMiterlimit="10"
						strokeWidth=".75"
					/>
					<path
						d="M118.62 36.55s-5.21 6.3-5.66 14.1c0 0-1.64 9.92 2.83 14.56 0 0 12.08 6.65 25.29-2.27 0 0 1-10.61.4-14.19 0 0 10.13-.28 10.53-3.59a2.31 2.31 0 00-2.18-3s-5.29-.74-8.35-3c0 0-3.36 3.84-12.2 2.12 0 .02-6.37-.6-10.66-4.73z"
						fill="#fff"
						stroke="#344357"
						strokeMiterlimit="10"
						strokeWidth=".75"
					/>
					<path
						d="M152.07 43.57a2.81 2.81 0 01-1.39 3.28c-1.95 1.49-9.2 1.9-9.2 1.9.65 1.79-.4 14.19-.4 14.19 4.08 4 17.15 1.65 17.15 1.65 5.26-.14 5.45-3.66 5.45-3.66a65.24 65.24 0 000-6.85A22.24 22.24 0 00160 41.69M121.79 60.53a3.14 3.14 0 013.07-3.15s3.18 0 3.06 3.69c0 0-.56 2.56-2.73 2.6a3.16 3.16 0 01-3.4-3.14z"
						fill="#fff"
						stroke="#344357"
						strokeMiterlimit="10"
						strokeWidth=".75"
					/>
					<path
						d="M150.73 59.06s-.43-1.65 2-1.68c0 0 3.47-.31 5.43-2.35 0 0 1-.92 1-1.39 0 0 2.4-4.22 3.08-4.33a8 8 0 016.11-2.44s2.39 1.46-.78 2.56c0 0-3.52.6-4.82 3.52 0 0-2.23 4.28-3.71 5.08a8.32 8.32 0 01-6.31 2.53 1.79 1.79 0 01-2-1.5zM149.89 25.66s4.39-.18 7.32 1.32c0 0 3.5-2.24 5-2 0 0 2.18 1.58 1.23 7 0 0 2.34 5.65-3.44 9.71a15.83 15.83 0 01-7.92 1.88s-.21-1-2.24-1.37c0 0-5.13-.58-8.26-2.94a.09.09 0 010-.14c.63-.4 4-2.85 4.56-9.13 0 0 3.83-.77 3.75-4.33z"
						fill="#fff"
						stroke="#344357"
						strokeMiterlimit="10"
						strokeWidth=".75"
					/>
					<path
						d="M113.15 59.77s-20.76 5.88-26.93 17.17c0 0-53.42 1.18-72.48 3.46M163.68 60.93s13.41 3.26 28.1 16c0 0 65.43 1.75 73.68 3.46"
						fill="none"
						stroke="#344357"
						strokeMiterlimit="10"
						strokeWidth=".75"
					/>
					<path fill="#fff" stroke="#fff" strokeMiterlimit="10" d="M.5 80.4h278.21v7.2H.5z" />
					<path
						d="M124.72 99.5a4.09 4.09 0 01-.13 5.65 5.29 5.29 0 01-3.3 1v2.17h-1.59V105h.74a5.29 5.29 0 002.69-.59 2.32 2.32 0 001-2.19 2.28 2.28 0 00-.64-1.71 2.31 2.31 0 00-1.72-.62 2.57 2.57 0 00-1.83.64 2.2 2.2 0 00-.69 1.69h-1.62a3.74 3.74 0 01.52-2 3.33 3.33 0 011.47-1.3 5 5 0 012.21-.46 4.14 4.14 0 012.89 1.04zm-3.19 10.5v2h-2v-2zM182 106.84a2.94 2.94 0 01.89 2.27 2.89 2.89 0 01-1 2.41 4.31 4.31 0 01-2.73.83v1.8h-1.31v-2.74h.61a4.34 4.34 0 002.23-.49 2.29 2.29 0 00.3-3.23 2 2 0 00-1.42-.52 2.12 2.12 0 00-1.52.54 1.82 1.82 0 00-.57 1.4h-1.34a3 3 0 01.43-1.62 2.8 2.8 0 011.22-1.08 4.17 4.17 0 011.82-.38 3.41 3.41 0 012.39.81zm-2.64 8.73v1.63h-1.66v-1.63z"
						transform="translate(-10.61 -98.52)"
					/>
				</svg>
			</div>
		</div>
	</React.Fragment>
);

export default NotFoundPage;
